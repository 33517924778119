import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo';
import Logo from '../assets/svg/logo.svg';

const Blog = (props) => {
  const { t } = useTranslation();
  const posts = props.data.posts.nodes;
  const first = posts.shift();

  return (
    <Layout>
      <Seo title={t('Blog')} path="/blog" />

      <div className="px-4 sm:px-6 mx-auto max-w-5xl">
        {first && (
          <div
            key={first.slug}
            className="grid grid-cols-1 md:grid-cols-5 gap-4 md:gap-8 items-start"
          >
            <div className="col-span-2">
              <div className="mb-2">
                {first.metadata.tags.map((tag) => (
                  <a
                    key={tag.contentful_id}
                    className="mr-2 text-xs text-oxford-blue uppercase"
                    href={`/blog/tags/${tag.contentful_id}`}
                  >
                    {tag.name}
                  </a>
                ))}
              </div>
              <h1 className="mt-0 text-3xl">
                <Link to={`/blog/${first.slug}/`} className="no-underline">
                  {first.title}
                </Link>
              </h1>
              {first.excerpt.internal.content && (
                <p className="last:mb-0">{first.excerpt.internal.content}</p>
              )}
            </div>
            <div className="col-span-3">
              <Link to={`/blog/${first.slug}/`} className="no-underline">
                {first.featuredImage ? (
                  <GatsbyImage
                    alt={first.featuredImage.title}
                    imgClassName="aspect-video"
                    image={first.featuredImage.gatsbyImageData}
                  />
                ) : (
                  <div className="flex items-center bg-oxford-blue aspect-video">
                    <Logo
                      alt={t('Copybara Logo')}
                      className="mx-auto w-1/3 h-auto text-white"
                    />
                  </div>
                )}
              </Link>
            </div>
          </div>
        )}
        <hr className="my-12" />
        {posts.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            {posts.map((post) => (
              <div
                key={post.slug}
                className="flex flex-col bg-white first:rounded-t last:rounded-b border border-gray-200"
              >
                <Link to={`/blog/${post.slug}/`} className="no-underline">
                  {post.featuredImage ? (
                    <GatsbyImage
                      alt={post.featuredImage?.title}
                      image={post.featuredImage?.gatsbyImageData}
                    />
                  ) : (
                    <div className="flex items-center bg-oxford-blue aspect-video">
                      <Logo
                        alt={t('Copybara Logo')}
                        className="mx-auto w-1/3 h-auto text-white"
                      />
                    </div>
                  )}
                </Link>
                <div className="p-4">
                  <div className="mb-2">
                    {post.metadata.tags.map((tag) => (
                      <a
                        key={tag.contentful_id}
                        className="mr-2 text-xs text-oxford-blue uppercase"
                        href={`/blog/tags/${tag.contentful_id}`}
                      >
                        {tag.name}
                      </a>
                    ))}
                  </div>
                  <h2 className="mt-0 text-xl">
                    <Link to={`/blog/${post.slug}/`} className="no-underline">
                      {post.title}
                    </Link>
                  </h2>
                  {post.excerpt.internal.content && (
                    <p className="last:mb-0">{post.excerpt.internal.content}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query {
    posts: allContentfulBlog(sort: { fields: publishDate, order: DESC }) {
      nodes {
        title
        slug
        excerpt {
          internal {
            content
          }
        }
        featuredImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
        }
        publishDate
        metadata {
          tags {
            contentful_id
            name
          }
        }
      }
    }
  }
`;
